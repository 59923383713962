import { combineReducers } from 'redux';

import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';

const { reducer: $bookings, actions: bookings, epics: bookingsEpics } = culdDux({
  name: 'bookings',
  url: '/api/bookings/bookings',
});

const { reducer: $bookingTemplates, actions: bookingTemplates, epics: bookingTemplatesEpics } = culdDux({
  name: 'bookingTemplates',
  url: '/api/bookings/bookingTemplates',
});

const { reducer: $deleteDocument, actions: deleteDocument, epics: deleteDocumentEpics } = updateDux({
  name: 'bookings.delete',
  url: '/api/bookings',
  getUrl: (url, { 0: { payload: { _id:{documentId, bookingId} } = {} } = {} }) => `${url}/${bookingId}/delete/${documentId}`,
});

const { reducer: $uploadDocument, actions: uploadDocument, epics: uploadDocumentEpics } = updateDux({
  name: 'bookings.upload',
  url: '/api/bookings',
  headers: { 'Content-Type': 'FormData' },
  getUrl: (url, { 0: { payload: { _id:{documentId, bookingId} } = {} } = {} }) => `${url}/${bookingId}/upload/${documentId}`,
});


export const actions = { bookings, bookingTemplates, deleteDocument, uploadDocument };
export const epics = [...bookingsEpics, ...bookingTemplatesEpics, ...deleteDocumentEpics, ...uploadDocumentEpics];
export default combineReducers({ $bookings, $bookingTemplates, $deleteDocument, $uploadDocument });
