import { combineReducers } from 'redux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';

const { reducer: $listEventLog, actions: listEventLog, epics: listEventLogEpics } = listDux({
  name: 'listEventLog',
  url: '/api/tracking/v2/push-events'
});

const { reducer: $eventLogDetail, actions: eventLogDetail, epics: eventLogDetailEpics } = detailsDux({
  name: 'eventLogDetail',
  url: '/api/tracking/v2/push-events'
});

const { reducer: $trackingDetail, actions: trackingDetail, epics: trackingDetailEpics } = detailsDux({
  name: 'trackingDetail',
  url: '/api/tracking/v2/tracking/track-by-reference',
  idField: 'referenceNumber',
  getUrl: (url, { 0: { payload: { referenceNumber } = {} } = {} }) => `${url}?referenceNumber=${referenceNumber}`,
});

export const actions = { listEventLog, eventLogDetail, trackingDetail};
export const epics = [...listEventLogEpics, ...eventLogDetailEpics, ...trackingDetailEpics];
export default combineReducers({ $listEventLog, $eventLogDetail, $trackingDetail});