import objectToQueryString from '../utils/objectToQueryString';

import { combineReducers } from 'redux';
import listDux from './lib/listDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';

const { reducer: $trackingSubscriptionByReference, actions: trackingSubscriptionByReference, epics: trackingSubscriptionByReferenceEpics } = listDux({
  name: 'trackingSubscriptionByReference',
  url: '/api/trackingmore/subscriptions',
  setDetailsFromListPayload: () => ({}),
  getUrl: (url, {0: {payload: {query}={}}={}}=[]) => {
    return `${url}/reference/?${objectToQueryString(query)}`
  },
});

const { reducer: $trackingSubscription, actions: trackingSubscription, epics: trackingSubscriptionEpics } = detailsDux({
  name: 'trackingSubscription',
  url: '/api/trackingmore/subscriptions',
});

const { reducer: $fetchTrackingSubscription, actions: fetchTrackingSubscription, epics: fetchTrackingSubscriptionEpics } = updateDux({
  name: 'fetchTrackingSubscription',
  url: '/api/trackingmore/subscriptions',
});

const { reducer: $subscribeTracking, actions: subscribeTracking, epics: subscribeTrackingEpics } = updateDux({
  name: 'subscribeTracking',
  url: '/api/shipments/v2/',
  getUrl: (url, { 0: { payload: { _id } = {} } = {} }) => `${url}/${_id}/subscribe-tracking-air/`,
});

const { reducer: $unSubscribeTracking, actions: unSubscribeTracking, epics: unSubscribeTrackingEpics } = updateDux({
  name: 'unsubscribeTracking',
  url: '/api/shipments/v2/',
  getUrl: (url, { 0: { payload: { _id } = {} } = {} }) => `${url}/${_id}/unsubscribe-tracking-air/`,
});

export const actions = { trackingSubscriptionByReference, trackingSubscription, fetchTrackingSubscription, subscribeTracking, unSubscribeTracking};
export const epics = [ ...trackingSubscriptionEpics, ...trackingSubscriptionByReferenceEpics, ...fetchTrackingSubscriptionEpics, ...subscribeTrackingEpics, ...unSubscribeTrackingEpics ];
export default combineReducers({ $trackingSubscriptionByReference, $trackingSubscription, $fetchTrackingSubscription, $subscribeTracking, $unSubscribeTracking, });