import { combineReducers } from 'redux';
import createDux from './lib/createDux';
import detailsDux from './lib/detailsDux';
import updateDux from './lib/updateDux';
import listDux from './lib/listDux';
import dux from './lib/dux';
import {ajaxEpic} from './lib/epic';

const { reducer: $createEnquiry, actions: createEnquiry, epics: createEnquiryEpics } = createDux({
  name: 'enquiries',
  url: '/api/enquiries/v2/planner',
  headers: { 'Content-Type': 'FormData' },
});

const { reducer: $enquiryDetails, actions: enquiryDetails, epics: enquiryDetailsEpics } = detailsDux({
  name: 'enquiries',
  url: '/api/enquiries/v2',
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'enquiries',
  url: '/api/enquiries/v2',
});

const { reducer: $addComment, actions: addComment, epics: addCommentEpics } = updateDux({
  name: 'enquiryComments',
  url: '/api/enquiries/v2/comment'
});

const { reducer: $getComments, actions: getComments, epics: getCommentsEpics } = listDux({
  name: 'getEnquiryComments',
  url: '/api/enquiries/v2/comments'
});

const { reducer: $orderConfirm, actions: orderConfirm, epics: orderConfirmEpics } = updateDux({
  name: 'orderConfirm',
  url: '/api/enquiries/v2/orderConfirm'
});

const { reducer: $getTNC, actions: getTNC, epics: getTNCEpics } = listDux({
  name: 'termAndCondition',
  url: '/api/enquiries/v2/termAndConditions'
});

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'EnquiryList',
  url: '/api/enquiries/v2/'
});

const {reducer: $summary, actions: summary, epics: summaryEpics} = dux({
  name: 'enquiries.$summary',
  initialState: {isFetching: false,},
  actions: [
    {name: 'get', getPayload: () => ({isFetching: true})},
    {name: 'set', getPayload: ({error, errorMessage='', ...summary}) => ({error, errorMessage, ...summary, isFetching: false})},
  ],
  epics: [
    ({getActionType, getAction}) => ajaxEpic({inActionType: getActionType('get'), outAction: getAction('set'), withLatestState: true, request: {url: '/api/enquiries/v2/summary', method: 'GET'},}),
  ],
});

const { reducer: $deleteDocument, actions: deleteDocument, epics: deleteDocumentEpics } = updateDux({
  name: 'enquiries.delete',
  url: '/api/enquiries/v2',
  getUrl: (url, { 0: { payload: { _id:{documentId, enquiryId} } = {} } = {} }) => `${url}/${enquiryId}/delete/${documentId}`,
});

const { reducer: $uploadDocument, actions: uploadDocument, epics: uploadDocumentEpics } = updateDux({
  name: 'enquiries.upload',
  url: '/api/enquiries/v2',
  headers: { 'Content-Type': 'FormData' },
  getUrl: (url, { 0: { payload: { _id:{documentId, enquiryId} } = {} } = {} }) => `${url}/${enquiryId}/upload/${documentId}`,
});

export const actions = { createEnquiry, enquiryDetails, update, addComment, getTNC, list, summary, orderConfirm, getComments, deleteDocument, uploadDocument};
export const epics = [...createEnquiryEpics, ...enquiryDetailsEpics, ...updateEpics, ...addCommentEpics, ...getTNCEpics, ...listEpics, ...summaryEpics, ...orderConfirmEpics, ...getCommentsEpics, ...deleteDocumentEpics, ...uploadDocumentEpics];
export default combineReducers({$createEnquiry,  $enquiryDetails, $update, $addComment, $getTNC, $list, $summary, $orderConfirm, $getComments, $deleteDocument, $uploadDocument });