import { combineReducers } from 'redux';

import createDux from './lib/createDux';
import updateDux from './lib/updateDux';
import culdDux from './lib/culdDux';
import listDux from './lib/listDux';

const { reducer: $getRates, actions: getRates, epics: getRatesEpics } = createDux({
  name: 'fxe.getRates',
  url: '/api/fxe/rates',
});

const { reducer: $getFXELMRRates, actions: getFXELMRRates, epics: getFXELMRRatesEpics } = createDux({
  name: 'fxeLmr.getRates',
  url: '/api/rates/v3/rateInventory/express-estimates/',
});

const { reducer: $shipments, actions: shipments, epics: shipmentsEpics } = culdDux({
  name: 'fxe.shipments',
  url: '/api/fxe/shipments',
});

const { reducer: $getTracking, actions: getTracking, epics: getTrackingEpics } = createDux({
  name: 'fxe.getTracking',
  url: '/api/fxe/tracking',
});

const { reducer: $getFedexLocations, actions: getFedexLocations, epics: getFedexLocationsEpics } = createDux({
  name: 'fxe.getFedexLocations',
  url: '/api/fxe/fedexLocations',
});

const { reducer: $pickups, actions: pickups, epics: pickupsEpics } = culdDux({
  name: 'fxe.pickups',
  url: '/api/fxe/pickups',
});

const { reducer: $contacts, actions: contacts, epics: contactsEpics } = culdDux({
  name: 'fxe.contacts',
  url: '/api/fxe/contacts',
  switchRequests: false,
});

const { reducer: $uploadFile, actions: uploadFile, epics: uploadFileEpics } = updateDux({
  name: 'fxe.uploadFile',
  url: '/api/fxe/shipments',
  getUrl: (url, { 0: { payload: { _id } = {} } = {} }) => `${url}/${_id}/uploadFile/`,
  headers: { 'Content-Type': 'FormData' },
});

const { reducer: $removeFile, actions: removeFile, epics: removeFileEpics } = updateDux({
  name: 'fxe.removeFile',
  url: '/api/fxe/shipments',
  getUrl: (url, { 0: { payload: { _id } = {} } = {} }) => `${url}/${_id}/removeFile/`,
});

const { reducer: $getDefaultSender, actions: getDefaultSender, epics: getDefaultSenderEpics } = listDux({
  name: 'fxe.getDefaultSender',
  url: '/api/fxe/default-sender',
});


const { reducer: $setDefaultSender, actions: setDefaultSender, epics: setDefaultSenderEpics } = createDux({
  name: 'fxe.setDefaultSender',
  url: '/api/fxe/default-sender/set-as-default/',
});

const { reducer: $getDefaultPrinter, actions: getDefaultPrinter, epics: getDefaultPrinterEpics } = listDux({
  name: 'fxe.getDefaultPrinter',
  url: '/api/fxe/default-printer',
});

const { reducer: $setDefaultPrinter, actions: setDefaultPrinter, epics: setDefaultPrinterEpics } = createDux({
  name: 'fxe.setDefaultPrinter',
  url: '/api/fxe/default-printer/set-as-default/',
});

const { reducer: $lhs, actions: lhs, epics: lhsEpics } = createDux({
  name: 'fxe.lhs',
  url: '/api/fxe/lhs',
  headers: { 'Content-Type': 'FormData' },
});

export const actions = { getRates, getFXELMRRates, shipments, getTracking, getFedexLocations, pickups, contacts, uploadFile, removeFile, getDefaultSender, setDefaultSender,  getDefaultPrinter, setDefaultPrinter, lhs };
export const epics = [...getRatesEpics, ...getFXELMRRatesEpics, ...shipmentsEpics, ...getTrackingEpics, ...getFedexLocationsEpics, ...pickupsEpics, ...contactsEpics, ...uploadFileEpics, ...removeFileEpics, ...getDefaultSenderEpics, ...setDefaultSenderEpics, ...getDefaultPrinterEpics, ...setDefaultPrinterEpics, ...lhsEpics];
export default combineReducers({ $getRates, $getFXELMRRates, $shipments, $getTracking, $getFedexLocations, $pickups, $contacts, $uploadFile, $removeFile, $getDefaultSender, $setDefaultSender, $getDefaultPrinter, $setDefaultPrinter,  $lhs  });